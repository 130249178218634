<template>
  <hit-base-input
    use-custom-height
    :full-width="false"
  >
    <hit-table
      :items="listData"
      :item-properties="itemProperties"
      item-id-property="$id"
      :config-store="configStore"
      items-selectable
      :addable="!disabled"
      :deletable="!disabled"
      dynamic-card
      :search-field="null"
      @delete="
        (items) =>
          container.deleteRowForDynamicInputList(items, listFormLocation)
      "
    >
      <template
        v-for="property in itemProperties"
        #[property.slotName]="{formData}"
      >
        <hit-container-dynamic-input
          :key="property.slotName"
          :container="container"
          :attribute="
            sectionId + '-' + formData.$id + '-' + property.propertyName
          "
          :type="property.type"
          :route="property.route"
          :options="property.options"
          :inline-input="true"
          :tag-filters="property.tagFilters"
          :for-injection="forInjection"
          :disabled="disabled"
          validate
          store-whole-object
          :browser-select-validation="browserSelectValidation"
        />
      </template>
      <template
        v-if="!disabled"
        #buttonAdd
      >
        <hit-button-add
          @click="
            () =>
              container.createRowForDynamicInputList(
                itemProperties,
                listFormLocation
              )
          "
        />
      </template>
    </hit-table>
  </hit-base-input>
</template>
<script>
import {HitBaseInput, HitButtonAdd, HitTable} from '@hit/components';
import HitContainerDynamicInput from './HitContainerDynamicInput.vue';
import {useConfigurationStore, useUserProfileStore} from '../../../store';

export default {
  name: 'HitContainerDynamicInputList',
  components: {
    HitBaseInput,
    HitTable,
    HitContainerDynamicInput,
    HitButtonAdd,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    listData: {
      type: Array,
      default: () => [],
    },
    listDef: {
      type: Object,
      default: null,
    },
    listFormLocation: {
      type: String,
      default: null,
    },
    createItemFunction: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * In some cases, we want to check the content of a selected record before
     * triggering the update event
     * When function returns false, update is skipped
     * (Example: staff used in injection form needs to have a cost_resource_id)
     */
    browserSelectValidation: {
      type: Function,
      required: false,
      default() {
        return true;
      },
    },
    /**
     * Needed  to prefix the list input attributes
     */
    sectionId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      configStore: useConfigurationStore(),
      userStore: useUserProfileStore(),
    };
  },
  computed: {
    itemProperties() {
      if (!this.listDef) {
        return [];
      }
      let result = [];
      for (const propKey in this.listDef.content) {
        let prop = this.listDef.content[propKey];
        let header = prop.definition.label;
        if (header && typeof header !== 'string') {
          header = header[this.userStore.localeSnakeCase];
        }
        result.push({
          header: header,
          order: prop.definition.order,
          propertyName: propKey,
          slotName: 'item.' + propKey + '.display',
          type: this.getPropertyDefinitionType(propKey),
          route: prop.definition.reference,
          tagFilters: prop.definition?.attributeFilter?.tag ?? null,
          options: prop.definition?.values ?? [],
        });
      }
      result.sort((a, b) => {
        return a.order - b.order;
      });
      return result;
    },

    /**
     * Boolean to check if the data is used for an injection
     */
    forInjection() {
      return 'injection' in this.listDef;
    },
  },
  methods: {
    getPropertyDefinitionType(propertyName) {
      if (
        this.listDef &&
        this.listDef.content &&
        this.listDef.content[propertyName]
      ) {
        return this.listDef.content[propertyName].definition.type;
      }
      return 'text';
    },
  },
};
</script>
