<template>
  <hit-container-input
    v-if="
      type === 'text' || type === 'number' || type === 'int' || type === 'date'
    "
    :type="type"
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
  <hit-container-input-checkbox
    v-else-if="type === 'checkbox'"
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
  <hit-container-input-rich-text
    v-else-if="type === 'richtext'"
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
  <hit-container-input-text-area
    v-else-if="type === 'textarea'"
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
  <hit-container-entity-select
    v-else-if="type === 'entity' && route === 'country'"
    :label-fields-localised="['designation']"
    route="country"
    :attribute="attribute"
    :container="container"
    :sort-config="{field: 'designation', order: 'asc', localised: true}"
    in-form
    validate
  />
  <hit-container-entity-browser
    v-else-if="type === 'entity'"
    :route="route"
    :attribute="attribute"
    :container="container"
    transform-selected-item-to-db-column-values
    :tag-filters="tagFilters"
    :for-injection="forInjection"
    :browser-select-validation="browserSelectValidation"
    only-load-active-records
    in-form
    validate
  />
  <hit-container-input-location
    v-else-if="type === 'location'"
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
  <hit-container-input-signature
    v-else-if="type === 'signature'"
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
  <hit-container-input-radio-button
    v-else-if="type === 'radio'"
    :attribute="attribute"
    :container="container"
    :options="options"
    in-form
    validate
  />
  <hit-container-input-file
    v-else-if="type === 'file'"
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
  <hit-container-input-hours
    v-else-if="type === 'hours'"
    :attribute="attribute"
    :container="container"
    :steps="steps"
    in-form
    validate
  />
  <hit-container-input-time
    v-else-if="type === 'time'"
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
  <hit-container-dynamic-input-list
    v-else-if="type === 'list'"
    :container="container"
    :section-id="sectionId"
    :list-data="listData"
    :list-def="listDef"
    :list-form-location="listFormLocation"
    :browser-select-validation="browserSelectValidation"
    in-form
    validate
  />
  <hit-container-input
    v-else
    :attribute="attribute"
    :container="container"
    in-form
    validate
  />
</template>
<script>
import HitContainerInput from './HitContainerInput.vue';
import HitContainerInputSignature from './HitContainerInputSignature.vue';
import HitContainerInputCheckbox from './HitContainerInputCheckbox.vue';
import HitContainerInputRichText from './HitContainerInputRichText.vue';
import HitContainerInputTextArea from './HitContainerInputTextArea.vue';
import HitContainerEntityBrowser from '../../hit/browser/HitContainerEntityBrowser.vue';
import {HitContainerEntitySelect} from '../../hit';
import HitContainerInputLocation from './HitContainerInputLocation.vue';
import HitContainerInputRadioButton from './HitContainerInputRadioButton.vue';
import HitContainerInputFile from './HitContainerInputFile.vue';
import HitContainerDynamicInputList from './HitContainerDynamicInputList.vue';
import HitContainerInputHours from './HitContainerInputHours.vue';
import HitContainerInputTime from './HitContainerInputTime.vue';

export default {
  name: 'HitContainerDynamicInput',
  components: {
    HitContainerEntitySelect,
    HitContainerInput,
    HitContainerInputCheckbox,
    HitContainerInputRichText,
    HitContainerInputTextArea,
    HitContainerEntityBrowser,
    HitContainerInputLocation,
    HitContainerInputSignature,
    HitContainerInputRadioButton,
    HitContainerInputFile,
    HitContainerDynamicInputList,
    HitContainerInputHours,
    HitContainerInputTime,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    listData: {
      type: Array,
      default: () => [],
    },
    listDef: {
      type: Object,
      default: null,
    },
    steps: {
      type: Number,
      default: 0,
    },
    tagFilters: {
      type: Array,
      required: false,
      default: null,
    },
    forInjection: {
      type: Boolean,
      required: false,
      default: false,
    },
    listFormLocation: {
      type: String,
      default: null,
    },

    /**
     * In some cases, we want to check the content of a selected record before
     * triggering the update event
     * When function returns false, update is skipped
     * (Example: staff used in injection form needs to have a cost_resource_id)
     */
    browserSelectValidation: {
      type: Function,
      required: false,
      default() {
        return true;
      },
    },

    /**
     * Needed  to prefix the list input attributes
     */
    sectionId: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    validate() {
      return false;
    },
  },
};
</script>
